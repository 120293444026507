<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">Grouping View</h4>
                <div v-if="groupingId">
                  <b-button variant="outline-red" squared @click="openDeleteModal" size="sm">Delete</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>
          <div v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Name </label>
                <b-form-input v-model="grouping.name" @blur="grouping.name.$touch()"></b-form-input>
              </b-col>
              <b-col cols="4">
                <label>Parent Grouping</label>
                <b-form-select v-model="grouping.parentGroupId">
                  <b-form-select-option v-for="(item, index) in groupings" :key="index" :value="item.id"
                    @blur="item.groupingID.$touch()">{{ item.name }}</b-form-select-option>
                </b-form-select>
              </b-col>
              <!-- <b-col cols="4">
                <label>Home Icon</label>
                <b-form-file v-model="file">
                  <template slot="file-name" slot-scope="{ names }">
                    <b-badge variant="dark">{{ names[0] }}</b-badge>
                    <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                      + {{ names.length - 1 }} More files
                    </b-badge>
                  </template>
                </b-form-file>
              </b-col> -->
              <!-- <b-col cols="4" v-if="applicationData.icon != '' && applicationData.icon != null">
                <label>Icon on file</label><br />
                <img width="65" height="65" v-bind:src="url + applicationData.icon" />
              </b-col> -->
            </b-row>
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="saveGrouping()" class="ml-2">Save
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="deleteModal" hide-footer hide-header-close title="Delete User">
      <b-row>
        <b-col cols="12">
          <label class="text-center">Are you sure you want to delete <span
              class="font-weight-bold text-red text-center">{{ this.grouping.name }}</span>?</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="outline-red" @click="hideDeleteModal" squared class="mr-2">Cancel</b-button>
          <b-button variant="red" @click="confirmDeleteGrouping" squared>Delete</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
  </div>
</template>
<script>
import {
  mapMutations,
  mapState
} from "vuex";
import {
  required
} from "vuelidate/lib/validators";
import api from "../../../api";
export default {
  name: "applicationView",
  data: () => ({
    iconSelected: "",
    file: null,
    state: 'show',
    groupings: [],
    grouping: {
      id: 0,
      name: null,
      parentGroup: null,
      parentGroupId: null
    },
    url: "",
    groupingId: null
  }),
  created() {
    this.url = api.Url;
    const error = (result) => {
      console.log(result);
    };
    const success = (result) => {
      this.groupings = result;
      this.groupingId = this.$route.params?.id;

      if (this.groupingId) {
        const e = (result) => {
          console.log(result);
        };
        const s = (result) => {
          this.grouping = result;
        };
        api.getGrouping(this.groupingId, s, e);
      }
    };
    api.getGroupings(success, error);
  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    goBackToSearch() {
      this.$router.push({
        path: '/admin/ManageGroupings/GroupingSearch'
      })
    },
    saveGrouping() {
      const s = () => {
        this.goBackToSearch();
        this.makeToast('Grouping updated successfuly!');
      };

      const e = (msg) => {
        console.log(msg);
        var newMsg = msg.substring(msg.indexOf(':') + 2, 76);
        this.makeToast(newMsg);
      };

      if (this.groupingId) {
        api.updateGrouping(this.grouping, s, e);
      } else {
        api.addGrouping(this.grouping, s, e);
      }
    },
    makeToast(message) {
      this.$bvToast.toast(`${message}`, {
        title: 'Something went wrong.',
        autoHideDelay: 5000,
        appendToast: false
      })
    },
    confirmDeleteGrouping() {
      const s = () => {
        this.goBackToSearch();
        this.makeToast('Grouping deleted successfuly!');
      };

      const e = (msg) => {
        console.log(msg);
        var newMsg = msg.substring(msg.indexOf(':') + 2, 76);
        this.makeToast(newMsg);
      };

      if (this.groupingId) {
        api.deleteGrouping(this.groupingId, s, e);
      }
    },
    openDeleteModal() {
      this.$bvModal.show('deleteModal')
    },
    hideDeleteModal() {
      this.$bvModal.hide('deleteModal')
    },
  },
  computed: {
    ...mapState([
      'SelectedApplicationAndService'
    ])
  },
  validations: {
    SelectedApplicationAndService: {
      name: {
        required
      },
    },
  },
}
</script>
<style scoped></style>